import { AnyKindOfDictionary, List } from "lodash";
import { uniqueId } from "lodash";

export class SchoolSearchModel {
  public schoolId: any;
  public schoolName: string;
  public type: string;
  public lat: any | null;
  public lng: any | null;
  public gradelevel: string;

  constructor() {
    this.schoolId = 0;
    this.schoolName = "";
    this.type = "";
    this.gradelevel = "";
  }
}

export class K12SchoolAbout {
  constructor(
    public uniqueId: string = "",
    public entityType: string = "",
    public profileType: string = "",
    public gradeservedcurrentsy: string = "",
    public currentSchoolYear: string = "",
    public gradeservedprevioussy: string = "",
    public previousSchoolYear: string = "",
    public agesGradesServedPrevious: string = "",
    public agesGradesServedCurrent: string = "",
    public id: string = "",
    public name: string = "",
    public districtCode: string = "",
    public districtName: string = "",
    public schooltype: string = "",
    public principalName: string = "",
    public cmo: string = "",
    public enrollmentLink: string = "",
    public totalEnrollment: string = "",
    public totalEnrollmentFormatted: string = "",
    public totalEnrollmentFormattedNA: string = "",
    public technologyfocus: string = "",
    public technologyfocusFormatted: string = "",
    public addressStreet: string = "",
    public addressCity: string = "",
    public addressState: string = "",
    public addressPostalCode: string = "",
    public addressLongitude: string = "",
    public addressLatitude: string = "",
    public phoneNumber: string = "",
    public twitter: string = "",
    public website: string = "",
    public facebook: string = "",
    public bandOffered: string = "",
    public musicOffered: string = "",
    public artsOffered: string = "",
    public danceOffered: string = "",
    public theatreOffered: string = "",
    public musicArtList: [] = [],
    public bandAdvancedBandFifthCredit: string = "",
    public bandAdvancedBandFourthCredit: string = "",
    public bandAdvancedBandSecondCredit: string = "",
    public bandAdvancedBandSixthCredit: string = "",
    public bandAdvancedBandThirdCredit: string = "",
    public bandBeginningBand: string = "",
    public bandIntermediateBand: string = "",
    public bandIntermediateBand3rdCredit: string = "",
    public bandMarchingBand: string = "",
    public apOffered: string = "",
    public apApArtHistory: string = "",
    public apApCalculusBc: string = "",
    public apApComputerScienceA: string = "",
    public apApEnvironmentalScience: string = "",
    public apApEuropeanHistory: string = "",
    public apApGovernmentAndPoliticsComparative: string = "",
    public apApGovernmentAndPoliticsUnitedStates: string = "",
    public apApHumanGeography: string = "",
    public apApPhysicsCElectricityAndMagnetism: string = "",
    public apApPhysicsCMechanics: string = "",
    public apApStudioArtDrawing: string = "",
    public apApStudioArt3dDesign: string = "",
    public apArtIii: string = "",
    public apArtIv: string = "",
    public apBiology: string = "",
    public apBiologyIi: string = "",
    public apBiologyIiApBiology: string = "",
    public apCalculus: string = "",
    public apCalculusApCalculusAb: string = "",
    public apCalculusApCalculusBc: string = "",
    public apChemistry: string = "",
    public apChemistryIi: string = "",
    public apChemistryIiApChemistry: string = "",
    public apChineseApChineseLanguageAndCulture: string = "",
    public apEconomicsApMacroeconomics: string = "",
    public apEconomicsApMicroeconomics: string = "",
    public apEnglishIii: string = "",
    public apEnglishIiiApEnglishLanguageAndComposition: string = "",
    public apEnglishIv: string = "",
    public apEnglishIvApEnglishLiteratureAndComposition: string = "",
    public apEnvironmentalScience: string = "",
    public apEnvironmentalScienceApEnvironmentalScience: string = "",
    public apFineArtsSurveyApMusicTheory: string = "",
    public apFrenchApFrenchLanguageAndCulture: string = "",
    public apFrenchIv: string = "",
    public apFrenchV: string = "",
    public apGermanApGermanLanguageAndCulture: string = "",
    public apLatinApLatin: string = "",
    public apLatinIv: string = "",
    public apMusicTheoryIi: string = "",
    public apPhysics: string = "",
    public apPhysicsIApPhysicsIAlgebraBased: string = "",
    public apPhysicsIApPhysicsIiAlgebraBased: string = "",
    public apProbabilityAndStatistics: string = "",
    public apProbabilityAndStatisticsApStatistics: string = "",
    public apSpanishApSpanishLanguageAndCulture: string = "",
    public apSpanishIv: string = "",
    public apSpanishV: string = "",
    public apStudioArtDesignApStudioArt2dDesign: string = "",
    public apStudioArtDrawingApStudioArtDrawing: string = "",
    public apUsHistory: string = "",
    public apUsHistoryApUsHistory: string = "",
    public apWorldGeography: string = "",
    public apWorldHistory: string = "",
    public apWorldHistoryApWorldHistory: string = "",
    public advancedPlacementList: [] = [],
    public deOffered: string = "",
    public deAdvMathFunctionsAndStatisticsDeCmat1303Introducto: string = "",
    public deAdvMathPreCalcDeCmat1223Trigonometry: string = "",
    public deAdvancedChorus: string = "",
    public deAdvancedMathFunctionsAndStatistics: string = "",
    public deAdvancedMathematicsPreCalculus: string = "",
    public deAgriscienceI: string = "",
    public deAgriscienceIi: string = "",
    public deAlgebraI: string = "",
    public deAlgebraIii: string = "",
    public deAlgebraIiiDeCmat1213CollegeAlgebra: string = "",
    public deAmericanGovernment: string = "",
    public deAnatomyAndPhysiology: string = "",
    public deArtHistoryDeCart2103ArtHistoryI: string = "",
    public deArtHistoryDeCart2113ArtHistoryIi: string = "",
    public deArtI: string = "",
    public deBasicTechnicalDrafting: string = "",
    public deBiologyDeCbio1013GeneralBiologyI: string = "",
    public deBiologyDeCbio1033GeneralBiologyISciMajors: string = "",
    public deBiologyIi: string = "",
    public deBiologyIiDeCbio1013GeneralBiologyI: string = "",
    public deBiologyIiDeCbio1023GeneralBiologyIi: string = "",
    public deBiologyIiDeCbio1033GeneralBiologyISciMajors: string = "",
    public deBiologyIiDeCbio1043GeneralBiologyIiSciMajors: string = "",
    public deCalculus: string = "",
    public deCalculusDeCmat2103AppliedCalculus: string = "",
    public deCalculusDeCmat21135CalculusI: string = "",
    public deCalculusDeCmat21235CalculusIi: string = "",
    public deChemistry: string = "",
    public deChemistryIDeCcem1013GeneralChemistrySurveyI: string = "",
    public deChemistryIDeCcem1103ChemistryI: string = "",
    public deChemistryIDeCcem1123ChemistryISciMajors: string = "",
    public deChemistryIi: string = "",
    public deChemistryIiDeCcem1003GeneralOrganicAndBiochemis: string = "",
    public deChemistryIiDeCcem1013GeneralChemistrySurveyI: string = "",
    public deChemistryIiDeCcem1103ChemistryI: string = "",
    public deChemistryIiDeCcem1113ChemistryIi: string = "",
    public deChemistryIiDeCcem1123ChemistryISciMajors: string = "",
    public deChemistryIiHonors: string = "",
    public deCivics1stSemester: string = "",
    public deCmadDrafting: string = "",
    public deCmadDrafting3Credit: string = "",
    public deDigitalMediaI1Credti: string = "",
    public deDigitalMediaI2Credits: string = "",
    public deDigitalMediaIi1Credit: string = "",
    public deDigitalMediaIi2Credits: string = "",
    public deDiscreteMathematics: string = "",
    public deDraftingAndDesignTechnologyI1Credit: string = "",
    public deDraftingAndDesignTechnologyI2Credits: string = "",
    public deDraftingAndDesignTechnologyIi2Credits: string = "",
    public deEarthScienceDeCgeo1113HistoricalGeology: string = "",
    public deEconomics: string = "",
    public deEconomicsDeCecn2213Macroeconomics: string = "",
    public deEconomicsDeCecn2223Microeconomics: string = "",
    public deEnglishIi: string = "",
    public deEnglishIii: string = "",
    public deEnglishIiiDeCenl1013EnglishCompositionI: string = "",
    public deEnglishIiiDeCenl1023EnglishCompositionIi: string = "",
    public deEnglishIiiDeCenl2173MajorAmericanWriters: string = "",
    public deEnglishIv: string = "",
    public deEnglishIvDeCenl1013EnglishCompositionI: string = "",
    public deEnglishIvDeCenl1023EnglishCompositionIi: string = "",
    public deEnglishIvDeCenl2103BritishLiteratureI: string = "",
    public deEnglishIvDeCenl2323IntroductionToLiterature: string = "",
    public deEnglishIvGiftedAndTalented: string = "",
    public deEnvironmentalScience: string = "",
    public deEnvironmentalScienceDeCevs1103EnvironmentalScience: string = "",
    public deEuropeanHistory: string = "",
    public deFineArtsSurvey: string = "",
    public deFineArtsSurveyDeCart1013ExploringTheArts: string = "",
    public deFineArtsSurveyDeCart1023IntroductionToVisualArts: string = "",
    public deFineArtsSurveyDeCdnc1013DanceAppreciation: string = "",
    public deFineArtsSurveyDeCmus1013MuscAppreciation: string = "",
    public deFrenchDeCfrn10131014ElemFrenchI: string = "",
    public deFrenchDeCfrn10231024ElemFrenchIi: string = "",
    public deFrenchDeCfrn20132014IntermediateFrenchI: string = "",
    public deFrenchI: string = "",
    public deFrenchIi: string = "",
    public deFrenchIii: string = "",
    public deGermanIi: string = "",
    public deGermanIii: string = "",
    public deLatinIi: string = "",
    public deLatinIii: string = "",
    public deMediaArtsI: string = "",
    public deMusicAppreciation: string = "",
    public deMusicHistory: string = "",
    public dePhysics: string = "",
    public dePhysicsIDeCphy2113PhysicsIAlgebraTrigBased: string = "",
    public dePhysicsIDeCphy2114PhysicsILecAndLab: string = "",
    public dePhysicsIDeCphy2133PhysicsICalculusBased: string = "",
    public dePhysicsIi: string = "",
    public dePreCalculus: string = "",
    public dePreCalculusDeCmat1233OrCmat1213And1223AlgAndTrig: string = "",
    public dePreCalculusHonors: string = "",
    public deProbabilityAndStatistics: string = "",
    public deProbabilityAndStatisticsDeCmat1303IntroductoryStat: string = "",
    public deProbabilityAndStatisticsHonors: string = "",
    public deSpanishDeCspn10131014ElemSpanishI: string = "",
    public deSpanishDeCspn10231024ElemSpanishIi: string = "",
    public deSpanishDeCspn20132014IntermediateSpanishI: string = "",
    public deSpanishElective: string = "",
    public deSpanishI: string = "",
    public deSpanishIi: string = "",
    public deSpanishIii: string = "",
    public deSpeechIii: string = "",
    public deSpeechIv: string = "",
    public deTheatreDeCthe2103ActingI: string = "",
    public deTheatreI: string = "",
    public deUsGovernmentDeCpol2013IntroToAmericanGovernment: string = "",
    public deUsHistory: string = "",
    public deUsHistoryDeChis2013AmericanHistoryI: string = "",
    public deUsHistoryDeChis2023AmericanHistoryIi: string = "",
    public deVisualArtsElectiveILocallyInitiatedElectiveVisArts: string = "",
    public deWesternCivDeChis1013WesternCivilizationI: string = "",
    public deWesternCivDeChis1023WesternCivilizationIi: string = "",
    public deWesternCivilization: string = "",
    public deWorldGeography: string = "",
    public deWorldGeographyDeCgrg2113WorldRegionalGeography: string = "",
    public deWorldHistory: string = "",
    public deWorldHistoryDeChis1113WorldCivilizationI: string = "",
    public deWorldHistoryDeChis1123WorldCivilizationIi: string = "",
    public dualEnrollmentList: [] = [],
    public giftedOffered: string = "",
    public jumpStart: string = "",
    public jsAgricultureFoodAndNaturalResources: string = "",
    public jsArchitectureAndConstruction: string = "",
    public jsArtsAVTechnologyAndCommunication: string = "",
    public jsBusinessManagement: string = "",
    public jsFinance: string = "",
    public jsEducationAndTraining: string = "",
    public jsGovernmentAndPublicAdministration: string = "",
    public jsHealthSciences: string = "",
    public jsHospitalityAndTourism: string = "",
    public jsHumanServices: string = "",
    public jsInformationTechnology: string = "",
    public jsLawPublicSafetyCorrectionsAndSecurity: string = "",
    public jsManufacturing: string = "",
    public jsMarketing: string = "",
    public jsStem: string = "",
    public jsTransportationDistributionAndLogistics: string = "",
    public jumpStartList: [] = [],
    public sealOffered: string = "",
    public sealSpanish: string = "",
    public sealFrench: string = "",
    public sealLatin: string = "",
    public sealOther: string = "",
    public sealGerman: string = "",
    public sealOfBiliteracyList: [] = [],
    public dualImmersion: string = "",
    public wliFrench: string = "",
    //public wliFrenchEarlyEd: string = "",
    public wliMandarin: string = "",
    //public wliMandarinEarlyEd: string = "",
    public wliSpanish: string = "",
    //public wliSpanishEarlyEd: string = "",
    public wliSpanishTwoWay: string = "",
    public wliList: [] = [],
    public foreignLangOffered: string = "",
    public langAmericanSignLanguageI: string = "",
    public langAmericanSignLanguageIi: string = "",
    public langArabicI: string = "",
    public langArabicIbLanguageAbInitio: string = "",
    public langArabicIi: string = "",
    public langChineseApChineseLanguageAndCulture: string = "",
    public langChineseI: string = "",
    public langChineseIbLanguageAbInitio: string = "",
    public langChineseIbLanguageB: string = "",
    public langChineseIi: string = "",
    public langChineseIii: string = "",
    public langForeignLanguageElectiveYear1: string = "",
    public langForeignLanguageElectiveYearIi: string = "",
    public langFrenchApFrenchLanguageAndCulture: string = "",
    public langFrenchDeCfrn10131014ElemFrenchI: string = "",
    public langFrenchDeCfrn10231024ElemFrenchIi: string = "",
    public langFrenchDeCfrn20132014IntermediateFrenchI: string = "",
    public langFrenchElective: string = "",
    public langFrenchGiftedAndTalentedFrenchIii: string = "",
    public langFrenchGiftedAndTalentedFrenchIv: string = "",
    public langFrenchI: string = "",
    public langFrenchIbLanguageAbInitio: string = "",
    public langFrenchIbLanguageB: string = "",
    public langFrenchIi: string = "",
    public langFrenchIii: string = "",
    public langFrenchIv: string = "",
    public langFrenchV: string = "",
    public langFrenchVi: string = "",
    public langGermanApGermanLanguageAndCulture: string = "",
    public langGermanI: string = "",
    public langGermanIi: string = "",
    public langGermanIii: string = "",
    public langGermanIv: string = "",
    public langItalianI: string = "",
    public langItalianIi: string = "",
    public langItalianIii: string = "",
    public langJapaneseI: string = "",
    public langJapaneseIi: string = "",
    public langLatinApLatin: string = "",
    public langLatinI: string = "",
    public langLatinIi: string = "",
    public langLatinIii: string = "",
    public langLatinIv: string = "",
    public langLatinV: string = "",
    public langRussianI: string = "",
    public langRussianIi: string = "",
    public langRussianIii: string = "",
    public langSpanishApSpanishLanguageAndCulture: string = "",
    public langSpanishDeCspn10131014ElemSpanishI: string = "",
    public langSpanishDeCspn10231024ElemSpanishIi: string = "",
    public langSpanishDeCspn20132014IntermediateSpanishI: string = "",
    public langSpanishElective: string = "",
    public langSpanishGiftedAndTalentedSpanishIii: string = "",
    public langSpanishGiftedAndTalentedSpanishIv: string = "",
    public langSpanishI: string = "",
    public langSpanishIbLanguageAbInitio: string = "",
    public langSpanishIbLanguageB: string = "",
    public langSpanishIi: string = "",
    public langSpanishIii: string = "",
    public langSpanishIv: string = "",
    public langSpanishV: string = "",
    public foreignLangOfferedList: [] = [],
    public volleyball: string = "",
    public crossCountry: string = "",
    public swimming: string = "",
    public football: string = "",
    public wrestling: string = "",
    public indoorTrackAndField: string = "",
    public soccer: string = "",
    public basketball: string = "",
    public powerlifting: string = "",
    public bowling: string = "",
    public tennis: string = "",
    public softball: string = "",
    public gymnastics: string = "",
    public golf: string = "",
    public outdoorTrackAndField: string = "",
    public baseball: string = "",
    public spirit: string = "",
    public other: string = "",
    public sportsList: [] = [],
    public athletics: string = "",
    public clubs: string = "",
    public clubsDramaTheaterDance: string = "",
    public clubsMusic: string = "",
    public clubsVisualArts: string = "",
    public clubsLitHumanities: string = "",
    public clubsStem: string = "",
    public clubsPeerTutoringMentoring: string = "",
    public clubsAdultMentoring: string = "",
    public clubsAffinityAssociations: string = "",
    public clubsBusiness: string = "",
    public clubsLeadershipAssociations: string = "",
    public clubsHonorSociety: string = "",
    public clubsPolitics: string = "",
    public clubsSchoolCommunityOrgs: string = "",
    public clubsSpeechDebate: string = "",
    public clubsCareerFocus: string = "",
    public clubsGaming: string = "",
    public clubsOther: string = "",
    public clubsList: [] = [],
    // public earlyEdLicenseNumber: string = "",
    // public earlyEdEmail: string = "",
    // public earlyEdMonday: string = "",
    // public earlyEdTuesday: string = "",
    // public earlyEdWednesday: string = "",
    // public earlyEdThursday: string = "",
    // public earlyEdFriday: string = "",
    // public earlyEdSaturday: string = "",
    // public earlyEdSunday: string = "",

    // About Early Learning School
    public earlyEdTipsNumber: string = "",
    public earlyEdLicenseNumber: string = "",
    public earlyEdName: string = "",
    public earlyEdLicenseType: string = "",
    public earlyEdLicensed: string = "",
    public earlyEdLicensedCenter: string = "",
    public earlyEdExemptCenter: string = "",
    public earlyEdPublicSchoolStatus: string = "",
    public earlyEdDirectorName: string = "",
    public earlyEdDirectorNameII: string = "",
    public earlyEdEmail: string = "",
    public earlyEdPhoneNumber: string = "",
    public earlyEdGrades: string = "",
    public earlyEdDistrictReport: string = "",
    public earlyEdSchoolReport: string = "",
    public earlyEdSpedOffered: string = "",
    public earlyEdMusicDanceVisualArtsOffered: string = "",
    public earlyEdDanceOffered: string = "",
    public earlyEdVisualArtsOffered: string = "",
    public earlyEdDualLanguageOffered: string = "",
    public earlyEdMusicOffered: string = "",
    public earlyEdGiftedPrograms: string = "",
    public earlyEd_foreign_lang_offered: string = "",
    public earlyEdCcap_available: string = "",
    public earlyEdCcapAvailableBinary: string = "",
    public earlyEdMonday: string = "",
    public earlyEdTuesday: string = "",
    public earlyEdWednesday: string = "",
    public earlyEdThursday: string = "",
    public earlyEdFriday: string = "",
    public earlyEdSaturday: string = "",
    public earlyEdSunday: string = "",
    public earlyEdTransportation: string = "",
    public earlyEdNightCare: string = "",
    public earlyEdNightCareBinary: string = "",
    public earlyEdTransportationBinary: string = "",
    public earlyEdTransportationBinaryFormatted: string = "",
    public wliSpanishEarlyEd: string = "",
    public wliFrenchEarlyEd: string = "",
    public wliMandarinEarlyEd: string = "",
    public publiclyFundedChildrenEnrolled: string = "",
    public publiclyFundedChildrenEnrolledFormatted: string = "",
    public publiclyFundedChildrenEnrolledFormattedNA: string = "",
    public afterCareBinary: string = "",
    public beforeCareBinary: string = "",
    public inspectionUrl: string = "",
    public seriousInjuriesUrl: string = "",
    public inspectionList: string = "",
    public starRating: string = "",
    public eceEnrollmentlink: string = "",
    public earlyEDAcademicOfferingList: string[] = [],
    public earlyEDMusicArtList: string[] = [],
    public esMsHsCombined: string = "",
    public earlyEdSchoolTypeList: string[] = [],
    public isElementarySchool: boolean = false,
    public isMiddleSchool: boolean = false,
    public isHighSchool: boolean = false
  ) {}
}

//This is for school spending data with al the expenditures
export class K12SchoolSpending {
  constructor(
    public studentExpenditure: string = "",
    public siteExpenditureState: string = "",
    public siteExpenditureFederal: string = "",
    public siteExpenditureTotal: string = "",
    public siteExpenditureTotalPercent: string = "",
    public centralExpenditureState: string = "",
    public centralExpenditureFederal: string = "",
    public centralExpenditureTotal: string = "",
    public centralExpenditureTotalPercent: string = "",
    public spendingTransportation: string = "",
    public spendingPupilInstructionalSupport: string = "",
    public spendingInstruction: string = "",
    public spendingAdministration: string = "",
    public otherSupport: string = "",
    public schoolSystem: string = "",
    public schoolSpendingOverTime: List<SchoolSpendingYearly> = [],
    public schoolSpendingByTypeBarChartData: Array<SchoolSpendingByTypeBarChartData> = []
  ) {}
}

//This is for school spending data with al the expenditures
export class CovidDashboard {
  constructor(
    public simulatedSPS: any = {},
    public schoolAttendanceRate: string[] = [],
    public schoolSystemAttendanceRate: string[] = [],
    public stateAttendanceRate: string[] = [],
    public attendanceRateYear: string[] = [],

    public modalityOfLearningLabel: string[] = [],
    public modalityOfLearningDataPoint: string[] = [],
    public modalityOfLearningDataPointCurrentYear: number = 0,
    public modalityOfLearningDataPointYearRange: string = "",

    //Cohort Graduation Rate
    public schoolCohortGraduationRate: string[] = [],
    public schoolSystemCohortGraduationRate: string[] = [],
    public stateCohortGraduationRate: string[] = [],
    public cohortGraduationRateYear: string[] = [],

    //LEAP Mastery and Basic Rate
    public lEAPMastery: string[] = [], //School, School System, State
    public lEAPBasic: string[] = [], //School, School System, State

    //LEAP Basic (Last 3 years) (3-8)
    public schoolLEAPBasic: string[] = [],
    public schoolSystemLEAPBasic: string[] = [],
    public stateLEAPBasic: string[] = [],
    public leapBasicYear: string[] = [],

    //LEAP Mastery (Last 3 years) (3-8)
    public schoolLEAPMastery: string[] = [],
    public schoolSystemLEAPMastery: string[] = [],
    public stateLEAPMastery: string[] = [],
    public leapMasteryYear: string[] = [],

    //LEAP Basic (Last 3 years)(High School)
    public schoolLEAPBasicHighSchool: string[] = [],
    public schoolSystemLEAPBasicHighSchool: string[] = [],
    public stateLEAPBasicHighSchool: string[] = [],
    public leapBasicYearHighSchool: string[] = [],

    //LEAP Mastery (Last 3 years)(High School)
    public schoolLEAPMasteryHighSchool: string[] = [],
    public schoolSystemLEAPMasteryHighSchool: string[] = [],
    public stateLEAPMasteryHighSchool: string[] = [],
    public leapMasteryYearHighSchool: string[] = []

  ) {}
}
export class SchoolSpendingByTypeBarChartData {
  constructor(
    public spendingTypeName: string = "",
    public schoolNameAverage: string = "",
    public schoolSystemAverage: string = "",
    public stateAverage: string = ""
  ) {}
}

export class SchoolSpendingYearly {
  constructor(
    public year: string = "",
    public schoolResult: string = "",
    public schoolSystem: string = "",
    public stateResult: string = ""
  ) {}
}
export class SchoolPerformance {
  constructor(
    public letterGrade: string = "",
    public studentAssessmentGrade: string = "",
    public actGrade: string = "",
    public graduationGrade: string = "",
    public strengthOfDiplomaGrade: string = "",
    public studentProgressGrade: string = ""
  ) {}
}

export class OverallLetterGrade {
  constructor(
    public letterGrade: string = "",
    public letterGradeYear: string = "",
    public letterGradeYearFormatted: string = "",
    public studentPerformanceScore: string = ""
  ) {}
}

export class OverallPerformance {
  constructor(
    public schoolCurrentYear: OverallLetterGrade = new OverallLetterGrade(),
    public schoolPreviousYear: OverallLetterGrade = new OverallLetterGrade(),
    public schoolSystemCurrentYear: OverallLetterGrade = new OverallLetterGrade(),
    public schoolSystemPreviousYear: OverallLetterGrade = new OverallLetterGrade(),
    public currentYear: string = "",
    public schoolReport: string = "",
    public comprehensiveIntervention: string = "",
    public louisianaComebackCampus: string = "",
    public purpleStarSchool: string = "",
    public equityHonorRoll: string = "",
    public topGainsBadge: string = "",
    public blueRibbon: string = "",
    public immersionCert: string = ""
  ) {}
}

export class StudentGroupBreakDown {
  constructor(
    public studyEconomicallyDisadvantaged: string = "",
    public studyEconomicallyDisadvantagedIntervention: string = "",

    public studyStudentsofColor: string = "",

    public studyStudentswithDisabilities: string = "",
    public studyStudentswithDisabilitiesIntervention: string = "",

    public studyEnglishLanguageLearner: string = "",
    public studyEnglishLanguageLearnerIntervention: string = "",

    public studyWhite: string = "",
    public studyWhiteIntervention: string = "",

    public colorAsian: string = "",
    public colorAsianIntervention: string = "",

    public colorAfricanAmerican: string = "",
    public colorAfricanAmericanIntervention: string = "",

    public colorHispanic: string = "",
    public colorHispanicIntervention: string = "",

    public colorMultipleRaces: string = "",
    public colorMultipleRacesIntervention: string = "",

    public populationEconomicallyDisadvantaged: string = "",
    public populationMilitaryConnected: string = "",
    public populationStudentsofColor: string = "",
    public populationStudentswithDisabilities: string = "",
    public populationEnglishLanguageLearner: string = "",
    public populationFacingHomelessness: string = "",
    public populationFoster: string = "",
    public populationNativeAmerican: string = "",
    public populationAsian: string = "",
    public populationAfricanAmerican: string = "",
    public populationHispanic: string = "",
    public populationMultipleRaces: string = "",
    public populationPacificIslander: string = "",
    public populationWhite: string = ""
  ) {}
}

export class TeacherWorkforce {
  public raceSchoolAsian: string;
  public raceSchoolBlack: string;
  public raceSchoolHispanic: string;
  public raceSchoolMultiple: string;
  public raceSchoolNativeAmerican: string;
  public raceSchoolPacificIslander: string;
  public raceSchoolWhite: string;

  public retentionSchool: string;
  public retentionDistrict: string;
  public retentionState: string;

  public attendanceSchool: string;
  public attendanceDistrict: string;
  public attendanceState: string;

  public earlyLiteracySchool: string;
  public earlyLiteracyDistrict: string;
  public earlyLiteracyState: string;

  public certTeacherSchool: string;
  public certTeacherDistrict: string;
  public certTeacherState: string;

  public certTeacherElementarySchool: string;
  public certTeacherElementaryDistrict: string;
  public certTeacherElementaryState: string;

  public certTeacherEnglishSchool: string;
  public certTeacherEnglishDistrict: string;
  public certTeacherEnglishState: string;

  public certTeacherMathSchool: string;
  public certTeacherMathDistrict: string;
  public certTeacherMathState: string;

  public certTeacherScienceSchool: string;
  public certTeacherScienceDistrict: string;
  public certTeacherScienceState: string;

  public certTeacherSocialStudiesSchool: string;
  public certTeacherSocialStudiesDistrict: string;
  public certTeacherSocialStudiesState: string;

  public certTeacherSpecialEducationSchool: string;
  public certTeacherSpecialEducationDistrict: string;
  public certTeacherSpecialEducationState: string;

  constructor() {
    this.raceSchoolAsian = "";
    this.raceSchoolBlack = "";
    this.raceSchoolHispanic = "";
    this.raceSchoolMultiple = "";
    this.raceSchoolNativeAmerican = "";
    this.raceSchoolPacificIslander = "";
    this.raceSchoolWhite = "";

    this.retentionSchool = "";
    this.retentionDistrict = "";
    this.retentionState = "";

    this.attendanceSchool = "";
    this.attendanceDistrict = "";
    this.attendanceState = "";

    this.earlyLiteracySchool = "";
    this.earlyLiteracyDistrict = "";
    this.earlyLiteracyState = "";

    this.certTeacherSchool = "";
    this.certTeacherDistrict = "";
    this.certTeacherState = "";

    this.certTeacherElementarySchool = "";
    this.certTeacherElementaryDistrict = "";
    this.certTeacherElementaryState = "";

    this.certTeacherEnglishSchool = "";
    this.certTeacherEnglishDistrict = "";
    this.certTeacherEnglishState = "";

    this.certTeacherMathSchool = "";
    this.certTeacherMathDistrict = "";
    this.certTeacherMathState = "";

    this.certTeacherScienceSchool = "";
    this.certTeacherScienceDistrict = "";
    this.certTeacherScienceState = "";

    this.certTeacherSocialStudiesSchool = "";
    this.certTeacherSocialStudiesDistrict = "";
    this.certTeacherSocialStudiesState = "";

    this.certTeacherSpecialEducationSchool = "";
    this.certTeacherSpecialEducationDistrict = "";
    this.certTeacherSpecialEducationState = "";
  }
}

export class DisciplineAndAttendance {
  constructor(
    public studentMissing: string = "",
    public studentZeroSuspensions: string = "",
    public studentMissingDistrictResult: string = "",
    public studentMissingStateResult: string = "",
    public studentZeroSuspensionsDistrictResult: string = "",
    public studentZeroSuspensionsStateResult: string = "",

    public studentMissingDisabilitySchoolResult: string = "",
    public studentMissingDisabilityDistrictResult: string = "",
    public studentMissingDisabilityStateResult: string = "",

    public studentMissingEconomicDisadvantageSchoolResult: string = "",
    public studentMissingEconomicDisadvantageDistrictResult: string = "",
    public studentMissingEconomicDisadvantageStateResult: string = "",

    // public studentMissingStudentOfColourSchoolResult: string = "",
    // public studentMissingStudentOfColourDistrictResult: string = "",
    // public studentMissingStudentOfColourStateResult: string = "",

    public studentMissingMaleSchoolResult: string = "",
    public studentMissingMaleDistrictResult: string = "",
    public studentMissingMaleStateResult: string = "",

    public studentMissingFemaleSchoolResult: string = "",
    public studentMissingFemaleDistrictResult: string = "",
    public studentMissingFemaleStateResult: string = "",

    public studentMissingFosterSchoolResult: string = "",
    public studentMissingFosterDistrictResult: string = "",
    public studentMissingFosterStateResult: string = "",

    public studentMissingHomelessSchoolResult: string = "",
    public studentMissingHomelessDistrictResult: string = "",
    public studentMissingHomelessStateResult: string = "",

    public studentMissingMigrantSchoolResult: string = "",
    public studentMissingMigrantDistrictResult: string = "",
    public studentMissingMigrantStateResult: string = "",

    public studentMissingAsianSchoolResult: string = "",
    public studentMissingAsianDistrictResult: string = "",
    public studentMissingAsianStateResult: string = "",

    public studentMissingBlackSchoolResult: string = "",
    public studentMissingBlackDistrictResult: string = "",
    public studentMissingBlackStateResult: string = "",

    public studentMissingHispanicSchoolResult: string = "",
    public studentMissingHispanicDistrictResult: string = "",
    public studentMissingHispanicStateResult: string = "",

    public studentMissingMultipleRacesSchoolResult: string = "",
    public studentMissingMultipleRacesDistrictResult: string = "",
    public studentMissingMultipleRacesStateResult: string = "",

    public studentMissingNativeAmericanSchoolResult: string = "",
    public studentMissingNativeAmericanDistrictResult: string = "",
    public studentMissingNativeAmericanStateResult: string = "",

    public studentMissingPacificIslanderSchoolResult: string = "",
    public studentMissingPacificIslanderDistrictResult: string = "",
    public studentMissingPacificIslanderStateResult: string = "",

    public studentMissingWhiteSchoolResult: string = "",
    public studentMissingWhiteDistrictResult: string = "",
    public studentMissingWhiteStateResult: string = "",

    public studentMissingEllSchoolResult: string = "",
    public studentMissingEllDistrictResult: string = "",
    public studentMissingEllStateResult: string = "",

    public studentZeroSuspensionsEconomicDisadvantageSchoolResult: string = "",
    public studentZeroSuspensionsEconomicDisadvantageDistrictResult: string = "",
    public studentZeroSuspensionsEconomicDisadvantageStateResult: string = "",

    public studentZeroSuspensionsStudentOfColourSchoolResult: string = "",
    public studentZeroSuspensionsStudentOfColourDistrictResult: string = "",
    public studentZeroSuspensionsStudentOfColourStateResult: string = "",

    public studentZeroSuspensionsDisabilitySchoolResult: string = "",
    public studentZeroSuspensionsDisabilityDistrictResult: string = "",
    public studentZeroSuspensionsDisabilityStateResult: string = "",

    public studentZeroSuspensionsEllSchoolResult: string = "",
    public studentZeroSuspensionsEllDistrictResult: string = "",
    public studentZeroSuspensionsEllStateResult: string = "",

    public studentZeroSuspensionsSchoolPerformOverTime: SchoolOverallPerformance[] = []
  ) {}
}

export class LeftPanel {
  constructor(
    public siteRating: string = "",
    public siteScore: string = "",
    public letterGrade: string = "",
    public studentPerformanceScore: string = "",
    public starRating: string = ""
  ) {}
}


export class K12School {
  constructor(
    public schoolAbout: K12SchoolAbout = new K12SchoolAbout(),
    public schoolSpending: K12SchoolSpending = new K12SchoolSpending(),
    public schoolPerformance: SchoolPerformance = new SchoolPerformance(),
    public overallPerformance: OverallPerformance = new OverallPerformance(),
    public studentGroupBreakDown: StudentGroupBreakDown = new StudentGroupBreakDown(),
    public teacherWorkforce: TeacherWorkforce = new TeacherWorkforce(),
    public disciplineAndAttendance: DisciplineAndAttendance = new DisciplineAndAttendance(),
    public ecAcademicPerformance: ECAcademicPerformance[],
    public studentPerformance: StudentPerformanceData,
    public studentProgress: StudentProgress[],
    public leftPanel: LeftPanel = new LeftPanel(),
    public covidDashboard: CovidDashboard = new CovidDashboard()
  ) {}
}

// export class DisciplineAndAttendance {
//     constructor(
//         public studentMissing: string = "",
//         public studentZeroSuspensions: string = "",
//         public studentMissingDistrictResult: string = "",
//         public studentMissingStateResult: string = "",
//         public studentZeroSuspensionsDistrictResult: string = "",
//         public studentZeroSuspensionsStateResult: string = "",
//         public studentMissingDisabilitySchoolResult: string = "",
//         public studentMissingDisabilityStateResult: string = "",
//         public studentMissingEconomicDisadvantageSchoolResult: string = "",
//         public studentMissingEconomicDisadvantageStateResult: string = "",
//         public studentMissingStudentOfColourSchoolResult: string = "",
//         public studentMissingStudentOfColourStateResult: string = "",
//         public studentZeroSuspensionsDisabilitySchoolResult: string = "",
//         public studentZeroSuspensionsDisabilityStateResult: string = "",
//         public studentZeroSuspensionsEconomicDisadvantageSchoolResult: string = "",
//         public studentZeroSuspensionsEconomicDisadvantageStateResult: string = "",
//         public studentZeroSuspensionsStudentOfColourSchoolResult: string = "",
//         public studentZeroSuspensionsStudentOfColourStateResult: string = "",
//         public studentZeroSuspensionsSchoolPerformOverTime: SchoolOverallPerformance[] = [],
//     ) {
//     }
// }



export class SchoolOverallPerformance {
  constructor(public year: string = "", public result: string = "") {}
}

export class SchoolSearchCriteria {
  public searchText: string;
  public entityType: string;
  public latitude: number;
  public longitude: number;
  public distance: number;
  public gradelevel: string;
  constructor() {
    this.searchText = "";
    this.entityType = "";
    this.latitude = 0.0;
    this.longitude = 0.0;
    this.distance = 0;
    this.gradelevel = "";
  }
}


export class SchoolCompareRequest {
  public uniqueIds: string;
  public latitude: number;
  public longitude: number;
  // public schoolYear: number;
  constructor() {
    this.uniqueIds = "";
    this.latitude = 0.0;
    this.longitude = 0.0;
    // this.schoolYear = 0;
  }
}

export class SchoolCompareResult {
  public uniqueId: string;
  public schoolName: string;
  public profileType: string;
  public schoolCompareRecords: SchoolCategoryValue[];
  constructor() {
    this.uniqueId = "";
    this.schoolName = "";
    this.profileType = "";
    this.schoolCompareRecords = [];
  }
}

export class SchoolCategoryValue {
  public categoryName: string;
  public sectionName: string;
  public value: string;
  constructor() {
    this.categoryName = "";
    this.sectionName = "";
    this.value = "";
  }
}

export class School {
  public id: number;
  public isFavorite: boolean;
  public academicOfferings: string[];
  public afterSchoolOpportunitiesAndClubs: string[];
  public alternative: string;
  public artsOffered: string;
  public athletics: string;
  public charter: string;
  public charterManagementOrganizationName: string;
  public clubs: string;
  public danceOffered: string;
  public distance: string;
  public districtName: string;
  public districtParishName: string;
  public eedDanceOffered: string;
  public eedForeignLangOffered: string;
  public eedGiftedPrograms: string;
  public eedMusicOffered: string;
  public enrollmentLink: string;
  public entityId: string;
  public exactMatch: boolean;
  public foreignLangOffered: string;
  public giftedPrograms: string;
  public gradeLetter: string;
  public gradeServedCY: string;
  public gradeServedPY: string;
  public gradesServed: string;
  public gradeLetterFormatted: string;
  public magnet: string;
  public musicArt: string[];
  public musicOffered: string;
  public name: string;
  public principalName: string;
  public profileType: string;
  public scholarship: string;
  public siteRating: string;
  public siteScore: string;
  public sports: string[];
  public sps: string;
  public starRating: string;
  public stem: string;
  public studentToDeviceRatio: string;
  public techFocus: string;
  public theatreOffered: string;
  public totalEnrollment: number;
  public traditionalPublic: string;
  public tutoring: string;
  public uniqueId: string;
  public worldLangImmersion: string;
  public latitude: string;
  public longitude: string;
  public isToCompare: boolean;
  public schoolIndex: number;

  constructor() {
    this.id = 0;
    this.isFavorite = false;
    this.academicOfferings = [];
    this.afterSchoolOpportunitiesAndClubs = [];
    this.alternative = "";
    this.artsOffered = "";
    this.athletics = "";
    this.latitude = "";
    this.longitude = "";
    this.charter = "";
    this.charterManagementOrganizationName = "";
    this.clubs = "";
    this.danceOffered = "";
    this.distance = "";
    this.districtName = "";
    this.districtParishName = "";
    this.eedDanceOffered = "";
    this.eedForeignLangOffered = "";
    this.eedGiftedPrograms = "";
    this.eedMusicOffered = "";
    this.enrollmentLink = "";
    this.entityId = "";
    this.exactMatch = false;
    this.foreignLangOffered = "";
    this.giftedPrograms = "";
    this.gradeLetter = "";
    this.gradeServedCY = "";
    this.gradeServedPY = "";
    this.gradesServed = "";
    this.gradeLetterFormatted = "";
    this.magnet = "";
    this.musicArt = [];
    this.musicOffered = "";
    this.name = "";
    this.principalName = "";
    this.profileType = "";
    this.scholarship = "";
    this.siteRating = "";
    this.siteScore = "";
    this.sports = [];
    this.sps = "";
    this.starRating = "";
    this.stem = "";
    this.studentToDeviceRatio = "";
    this.techFocus = "";
    this.theatreOffered = "";
    this.totalEnrollment = 0;
    this.traditionalPublic = "";
    this.tutoring = "";
    this.uniqueId = "";
    this.worldLangImmersion = "";
    this.isToCompare = false;
    this.schoolIndex = 0;
  }
}



export class SchoolYear {
  constructor(
    public currentSchoolYearRange: string = "",
    public previousSchoolYearRange: string = "",
    public currentYear: number = -1,
    public nextYear: number = -1,
    public previousYear: number = -1
  ) {}
}

export class ECAcademicPerformance {
  constructor(
    public sectionName: string = "",
    public performances: ECPerformance[],
    public sitePerformances: SitePerformance[]
  ) {}
}

export class ECPerformance {
  constructor(
    public id: number = 0,
    public label: string = "",
    public rating: string = "",
    public score: string = "",
    public scoreYear: string = "",
    public bold: boolean = false
  ) {}
}

export class SitePerformance {
  constructor(
    public id: number = 0,
    public type: string = "",
    public data: ECPerformance[]
  ) {}
}

export class StudentPerformanceData {
  constructor(
    public assessmentScore: string = "",
    public actScore: string = "",
    public diplomaScore: string = "",
    public DiplomaGrade: string = "",
    public coreCreditAccumulationScore: string = "",
    public coreCreditAccumulationGrade: string = "",
    public dropoutCreditAccumulationScore: string = "",
    public dropoutCreditAccumulationGrade: string = "",
    public twelfthGradeStrengthOfDiplomaScore: string = "",
    public twelfthGradeStrengthOfDiplomaGrade: string = "",
    public graduationScore: string = "",
    public progressGrade: string = "",
    public progressScore: string = "",
    public progressScoreDistrict: string = "",
    public progressScoreState: string = "",
    public growthEsms: string = "",
    public growthEsmsDistrict: string = "",
    public growthEsmsState: string = "",
    public mastery: string = "",
    public masteryDistrict: string = "",
    public masteryState: string = "",
    public creditAccumulation: string = "",
    public creditAccumulationDistrict: string = "",
    public creditAccumulationState: string = "",
    public gradRate4yr: string = "",
    public gradRate4yrDistrict: string = "",
    public gradRate4yrState: string = "",
    public gradsAnyCredentials: string = "",
    public gradsAnyCredentialsDistrict: string = "",
    public gradsAnyCredentialsState: string = "",
    public collegeEnrollment: string = "",
    public collegeEnrollmentDistrict: string = "",
    public collegeEnrollmentState: string = "",
    public fafsaRates: string = "",
    public fafsaRatesDistrict: string = "",
    public fafsaRatesState: string = "",

    public act21: string = "",
    public act21District: string = "",
    public act21State: string = "",

    public actComposite: string = "",
    public actCompositeDistrict: string = "",
    public actCompositeState: string = "",

    public masteryHighSchool: string = "",
    public masteryHighSchoolDistrict: string = "",
    public masteryHighSchoolState: string = "",

    public studentsStayingOnTrack: string = "",
    public studentsStayingOnTrackDistrict: string = "",
    public studentsStayingOnTrackState: string = "",

    public coreCredentialRateAlt: string = "",
    public coreCredentialRateAltDistrict: string = "",
    public coreCredentialRateAltState: string = "",

    public sideBarScores: StudentPerformanceScore[] = []
  ) {}
}

export class StudentPerformanceScore {
  constructor(
    public id: number = 0,
    public metric: string = "",
    public heading: string = "",
    public data: any = [],
    public chartData: SchoolOverallPerformance[] = []
  ) {}
}

export class StudentProgress {
  public studentDemoedTopGrowth: string;
  public schoolSystemAverage: string;
  public stateAverage: string;
  public recordsForSchoolType: string;
  public economicDisadvantage: string;
  public economicDisadvantageSchoolSystem: string;
  public economicDisadvantageState: string;

  public growthInMath: string;
  public growthInMathSchoolSystem: string;
  public growthInMathState: string;

  public growthInEngArts: string;
  public growthInEngArtsSchoolSystem: string;
  public growthInEngArtsState: string;

  public disability: string;
  public disabilitySchoolSystem: string;
  public disabilityState: string;

  public minority: string;
  public minoritySchoolSystem: string;
  public minorityState: string;

  public growthInEngLearner: string;
  public growthInEngLearnerSchoolSystem: string;
  public growthInEngLearnerState: string;

  constructor() {
    this.studentDemoedTopGrowth = "";
    this.schoolSystemAverage = "";
    this.stateAverage = "";
    this.recordsForSchoolType = "";
    this.economicDisadvantage = "";
    this.economicDisadvantageSchoolSystem = "";
    this.economicDisadvantageState = "";

    this.growthInMath = "";
    this.growthInMathSchoolSystem = "";
    this.growthInMathState = "";

    this.growthInEngArts = "";
    this.growthInEngArtsSchoolSystem = "";
    this.growthInEngArtsState = "";

    this.disability = "";
    this.disabilitySchoolSystem = "";
    this.disabilityState = "";

    this.minority = "";
    this.minoritySchoolSystem = "";
    this.minorityState = "";

    this.growthInEngLearner = "";
    this.growthInEngLearnerSchoolSystem = "";
    this.growthInEngLearnerState = "";
  }
}

